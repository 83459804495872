// all api paths
export const API_PATH = {
  USER: {
    FORGOT_PASSWORD: `/forgot-password`,
    GET_AGENT_STATS: `/bets/get-agent-stats`,
    GET_BALANCE: `/player/get-balance`,
    GET_BONUSES: `/bonuses`,
    GET_HISTORY: `/bets/get-history`,
    CREATE_INVITATION_CODE: `/create-invitation-code`,
    GET_RAF: `/raf/all`,
    GET_ALL_TOURS: `/tours/get-all-tours`,
    MARK_TOUR_AS_DISPLAYED: `/mark-tour-as-displayed`,
    GET_TRANSACTIONS: `/transactions`,
    GET_USER_INFO: `/get-user-info`,
    VALIDATE_IMPERSONATE_PLAYER: `/impersonate/player/validate-impersonate-player`,
    INVITE_CODE: code => `/signup/${code}`,
    LOGIN: `/login`,
    REDEEM: id => `/raf/redeem/${id}`,
    RESET_PASSWORD: `/reset-password`,
    SEND_EMAIL_CONFIRMATION: `/bets/send-email-confirmation`,
    SEND_SMS: `/send-sms`,
    SIGNUP: `/signup`,
    UPDATE_USER_INFO: `/update-user-info`,
    USER_INFO: `/user-info`,
    VERIFY_EMAIL: `/verify-email`,
    VERIFY_PHONE: `/check-phone-code`,
    VERIFY_PHONE_NUMBER: `/users/user/validate-phonenumber`,
    VERIFY_IDENTITY: `/verification-document`,
    COGNITO_CREATE_RETRY_FLOW_SESSION: `/cognito/create-retry-flow-session`,
    TPI_VERIFICATION_EMAIL_PLAYER: `/tpi-cashier/verification-email-player`,
    TPI_EXTERNAL_AUTH: `/tpi-cashier/external-auth`,
    EXTERNAL_SET_PASSWORD: `/external-cashier-set-password`,
  },
  BRACKET: {
    BUY_BRACKET: id => `/brackets/bracket/buy-bracket-entries-user/${id}`,
    GET_ENTRIES: `/brackets/bracket/get-entries-by-user`,
    GET_ENTRIES_AVAILABLE: `/brackets/bracket/get-entries-avaliable-by-user`,
    GET_TOP_PLAYERS: id => `/brackets/bracket/get-tops-players-by-bracket/${id}`,
    UPDATE_BRACKET: `/brackets/bracket/update-bracket-entries-user`,
  },
  CASHIER: {
    ADD_BANK: `/pcashier/add-bank`,
    COMPLETE_CHALLENGE: `/pcashier/complete-challenge`,
    CHECK_CBIO: `/pcashier/check-cbio`,
    CHECK_CODE: `/pcashier/check-code`,
    INSTITUTIONS: `/pcashier/institutions`,
    GET_BANK: `/pcashier/get-bank-reference`,
    GET_BONUS_BY_CODE: `/pcashier/get-bonus-by-code`,
    GET_BONUSES: param => `/pcashier/bonuses${param}`,
    GET_FREE_TIMES: `/fee-free-time/get-by-payment-method`,
    GET_ONLINE_HOURS: `/pcashier/get-online-hours`,
    GET_VALIDATE_USE_WITHDRAWAL_VERIFICATION_CODE: `/pcashier/validate-use-verification-code`,
    GET_METHODS: param => `/pcashier/methods${param}`,
    GET_PLAYER_BANK: `/pcashier/banks`,
    GET_ROLLOVERS: `/pcashier/rollovers`,
    GET_TRANSACTION_LIMIT: `/pcashier/deposit-limits`,
    GET_TRANSACTION_STATUS: `/pcashier/get-transaction-status`,
    GET_WITHDRAWAL_HOLD_BALANCE: `/pcashier/withdrawal-hold-balance`,
    INSERT_TRANSACTION: `/pcashier/insert-transaction`,
    LOGIN_TO_INSTITUTION: `/pcashier/login-to-institution`,
    REMOVE_BANK: `/pcashier/remove-bank`,
    SEND_SMS: `/pcashier/send-sms`,
    SET_DEFAULT_BANK: `/pcashier/set-default-bank`,
    LIST_PLAYER_PROFILE_SETTINGS_PAYMENT_METHOD_BONUSES: `/pcashier/list-player-profile-settings-payment-method-bonuses`,
    LIST_PENDING_TRANSACTION_APPROVE_PURCHASE: `/pcashier/list-pending-transaction-approve-purchase`,
    APPROVE_PURCHASE: `/pcashier/approve-purchase`,
  },
  GAME_SELECTION: {
    CREATE_BET: `/bets/wager/confirm`,
    GET_ACTIVE_BY_PLAYER: `/bets/league/get-active-by-player`,
    GET_SCHEDULE: `/bets/get-schedule-by-user-and-league`,
    GET_OPEN_BETS: `/bets/get-open-bets`,
    GET_TEASERS_INFO: `/bets/get-teasers-info`,
    PLACE_BET: `/bets/wager/post`,
    COMPILE: `/bets/wager/compile`,
    TV_CHANNELS: `/tv/get-active-channels`,
  },
  GUIDE: {
    GET_ALL_CATEGORIES: `/guides-category/get-categories-with-guides`,
    GET_ALL: `/guides/get-all-player-guides`,
  },
  LANDING_PAGE: {
    DELETE: `/LandingPage/Delete`,
    GET_ALL_PAGES: `/LandingPage/GetAllPages`,
    SET_ACTIVE: `/LandingPage/SetActive`,
    CREATE: `/LandingPage/Create`,
    UPDATE: `/LandingPage/Update`,
  },
  PROMOTION: {
    GET: `/bets/get-promotions`,
  },
  RULE: {
    GET_CATEGORIES: `/rules/get-player-rules-categories`,
    GET_RULE: id => `/rules/get-rules-by-category/${id}`,
  },
  TPI: {
    ADD_UPDATE_FAVORITE_GAME: `/ptpi/add-update-favorite-game-player`,
    ADD_UPDATE_GAME_RATING: `/ptpi/add-update-game-rating-player`,
    CASINO_BONUS: id => `/ptpi/casino-bonus/${id}`,
    CASINO_BONUSES: `/ptpi/casino-bonuses`,
    CATEGORIES_WITH_GAMES: `/ptpi/categories-with-games`,
    CASINO_LOBBY: `/ptpi/casino-lobby`,
    INIT_PROVIDER: `/ptpi/init-provider`,
    GET_GAME_RATING: `/ptpi/get-game-rating`,
  },

  PROPS_BUILDER: {
    GET_LEAGUES: `/props-builder/get-leagues`,
    GET_EVENTS: `/props-builder/get-events`,
  },
  DGS: {
    MESSAGES: `/DGS/message-by-languagues/get-by-user`,
  },
  PICKS: {
    GET_BY_USER: `/pick-user-entry/get-by-user`,
    GET_ACTIVE: `/pick/get-active-picks`,
    BUY_PICKS: id => `/pick-user-entry/buy-pick/${id}`,
    GET_TOP_WINNERS_BY_PICK: id => `/pick-user-entry/get-top-winners-by-pick/${id}`,
  },
};
