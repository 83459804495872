import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { ILogin } from '../../../../models/login';
import { Loading } from '../../../../shared/loading/loading.component';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getHistory } from '../../../../utils/history.util';
import { IAppSettingsDto } from '../../../../models/common';

export interface ExternalProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  loggedInUser: any;
  validToken: boolean | null;
  app_settings: IAppSettingsDto;
}
export interface ExternalActionProps {
  loginAction: (login: ILogin) => void;
  getUserInfoAction: () => void;
  TpiVerificationEmailPlayerAction: (code: string) => void;
}

export interface InternalProps {
  classes: any;
}

function ExtLoginPageComp(
  props: InternalProps & ExternalProps & ExternalActionProps,
): React.ReactElement<InternalProps & ExternalProps & ExternalActionProps> {
  const { getUserInfoAction, isLoading, TpiVerificationEmailPlayerAction, validToken, app_settings } = props;
  const [loading, setLoading] = React.useState<boolean>(true);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let user: any = null;

  useEffect(() => {
    try {
      if (params.verify) {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        TpiVerificationEmailPlayerAction(params.verify);
        return;
      }

      if (params.token && params.username) {
        localStorage.setItem('token', params.token);
        localStorage.setItem('username', params.username);
      }
      getUserInfoAction();
    } catch (error) {
      setLoading(false);
      getHistory().push(`/`);
    }
  }, []);

  useEffect(() => {
    try {
      if (validToken === true) getHistory().push(`/cashier`);

      if (validToken === false) {
        user = localStorage.getItem('user');

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      getHistory().push(`/`);
    }
  }, [validToken]);

  return (
    <>
      <Loading loading={isLoading || loading} url_logo={app_settings.logo.url} />
      {!user && !isLoading && !loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            padding: 0,
          }}
        >
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            <Typography>Invalid Player [T]</Typography>
          </Alert>
        </div>
      )}
    </>
  );
}

export const styles: any = createStyles({
  form: {
    padding: 0,
  },
  moveForgetPasswordToRight: {
    paddingTop: 20,
    paddingBottom: 10,
    fontSize: '16px',
  },
  moveForgotUsernameToLeft: {
    paddingTop: 20,
    paddingBottom: 10,
    color: 'white',
    fontSize: '16px',
  },
  inputText: {
    marginBottom: '.5rem',
    marginTop: '1rem',
  },
});

export const LoginPage = withStyles(styles)(ExtLoginPageComp);
